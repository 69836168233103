<template>
  <div>
    <PageHeader
      :links="[
        { url: '#', label: 'Home' },
        { url: '#service', label: 'Service' },
        { url: '#pricing', label: 'Pricing' },
        { url: '#faq', label: 'FAQ' },
        { url: '/docs', label: 'Docs' },
        { url: '/dashboard', label: 'Dashboard', class: 'md:hidden' },
      ]"
    />
    <main>
      <PageBlock>
        <div class="head">
          <h1>Simple OTA updates</h1>
          <div>
            Push new code to your <span>react-native</span> and <span>expo</span> mobile app users without store
            submission!
          </div>
        </div>
        <div id="service" class="px-4 pt-4 lg:pt-12">
          <div class="flex justify-evenly items-center max-lg:flex-col max-lg:justify-center">
            <div>
              <ul>
                <li><span>✓</span> Instant patches and features deployment</li>
                <li><span>✓</span> Integrate in minutes</li>
                <li><span>✓</span> Compliant with App Store and Play Store</li>
                <li><span>✓</span> About 3x cheaper than EAS update</li>
              </ul>
              <div>
                <BlueButton href="/docs#getting-started" class="mt-5">
                  <div class="text-base">Get started</div>
                </BlueButton>
              </div>
            </div>
            <div class="max-lg:pt-10">
              <div class="terminal">
                <div class="flex mt-1 mb-4">
                  <span class="w-3 h-3 bg-[#ff5f58] rounded-full" />
                  <span class="w-3 h-3 bg-[#ffbd2e] rounded-full ml-2" />
                  <span class="w-3 h-3 bg-[#29ca41] rounded-full ml-2" />
                </div>
                <code>
                  <span>$</span> npx otago deploy --project my-app<br />
                  <div class="mt-3">
                    ✓ Bundle assets<br />
                    ✓ Upload assets<br />
                    ✓ Generate manifest<br />
                    ✓ Deploy<br />
                  </div>
                </code>
              </div>
            </div>
          </div>
        </div>
      </PageBlock>

      <PageBlock bg-alt bg-skewed>
        <h2>See it in action</h2>
        <video
          controls
          autoplay
          loop
          muted
          playsinline
          class="max-w-4xl w-full mx-auto border border-zinc-900 rounded-lg shadow-lg hover:border-blue-400"
        >
          <source src="https://cdn.otago.dev/video/otago_demo.mp4" type="video/mp4" />

          <img src="/images/dashboard.png" alt="Dashboard screenshot" />
        </video>
      </PageBlock>

      <PageBlock>
        <h2>Our story</h2>
        <div class="card story-card">
          <FwbAvatar size="xl" img="/images/julien.png" />
          <div class="content">
            <p>Hi, I'm Julien, mobile app developer <span>👋</span></p>
            <p>
              I love Expo and I've been using it since 2019. Back then their OTA updates were really simple, I used them
              a lot to fix minor problems. I've seen the changes happening to EAS updates and the complexity they added
              (branches, sub-branches...).
            </p>
            <p>
              In 2022, I had to set up my first custom update server when I worked for a startup which had to pay 10K a
              month for an app with 300K actives users <span>😱</span>. That's why I wanted to share with you a simpler
              and less expensive alternative: Otago. Happy coding! <span>🚀</span>
            </p>
          </div>
        </div>
      </PageBlock>

      <PageBlock id="pricing" bg-alt bg-skewed>
        <div class="pricing">
          <NuxtLink class="card pricing-card" to="/login?from=pricing-tryout">
            <div>
              <h3>Free to try</h3>
              <p><span>✓</span> 100K free user installs</p>
              <p><span>✓</span> Unlimited apps</p>
              <p><span>✓</span> Unlimited team</p>
              <p><span>✓</span> Dedicated support</p>
              <p><span>✓</span> No credit card required</p>
            </div>
          </NuxtLink>
          <NuxtLink class="card pricing-card pricing-main" to="/login?from=pricing-simple">
            <div class="flex flex-col justify-between h-full">
              <div class="text-center">
                <h3>One simple pricing</h3>
                <p class="pt-3 pb-10">
                  <span class="text-5xl font-semibold">${{ credit.price }}</span> / 10K user installs
                </p>
                <p class="italic">
                  No expiration
                  <br />
                  Define automatic refills
                </p>
              </div>
              <div class="grid justify-items-center pt-4">
                <BlueButton>Get Started</BlueButton>
              </div>
            </div>
          </NuxtLink>
          <NuxtLink class="card pricing-card" to="/login?from=pricing-volume-discount">
            <div>
              <h3>Volume discounts</h3>
              <div class="text-center">
                <p><span>✓</span> Refill for a year in advance and get:</p>
                <p v-for="(bonus, threshold) in credit.bonuses" :key="threshold">
                  <span class="font-semibold">{{ bonus * 100 }}%</span> bonus for ${{ threshold }}
                </p>
              </div>
            </div>
          </NuxtLink>
        </div>
      </PageBlock>

      <PageBlock id="faq">
        <div class="faq">
          <h2>FAQ</h2>
          <FwbTimeline>
            <FwbTimelineItem>
              <FwbTimelinePoint><span class="font-bold">?</span></FwbTimelinePoint>
              <FwbTimelineContent>
                <FwbTimelineTitle>How does Otago work?</FwbTimelineTitle>
                <FwbTimelineBody>
                  Otago is a custom server for expo-updates library. When the SDK is installed on your mobile
                  application, each time a user starts the app, it will check on Otago for updates. If there is a new
                  version, it is downloaded and installed.
                </FwbTimelineBody>
              </FwbTimelineContent>
            </FwbTimelineItem>
            <FwbTimelineItem>
              <FwbTimelinePoint><span class="font-bold">?</span></FwbTimelinePoint>
              <FwbTimelineContent>
                <FwbTimelineTitle>What is the difference with Expo / EAS update?</FwbTimelineTitle>
                <FwbTimelineBody>
                  EAS works great, but we feel it has become complicated over time. We wanted to propose a simpler, less
                  expensive alternative.
                </FwbTimelineBody>
              </FwbTimelineContent>
            </FwbTimelineItem>
            <FwbTimelineItem>
              <FwbTimelinePoint><span class="font-bold">?</span></FwbTimelinePoint>
              <FwbTimelineContent>
                <FwbTimelineTitle>What is a "user install"?</FwbTimelineTitle>
                <FwbTimelineBody>
                  Each time a user starts your app, if there is a new version, it is downloaded and that counts as a
                  "user install". If the user is up to date, nothing happens. If the same user has your app on 2 devices
                  and updates both, it counts as 2 user installs.
                </FwbTimelineBody>
              </FwbTimelineContent>
            </FwbTimelineItem>
            <FwbTimelineItem>
              <FwbTimelinePoint><span class="font-bold">?</span></FwbTimelinePoint>
              <FwbTimelineContent>
                <FwbTimelineTitle>Can I use Otago on an existing app?</FwbTimelineTitle>
                <FwbTimelineBody>
                  Yes, you can use Otago for old and new apps, all expo versions from v42, and react-native apps.
                </FwbTimelineBody>
              </FwbTimelineContent>
            </FwbTimelineItem>
            <FwbTimelineItem>
              <FwbTimelinePoint><span class="font-bold">?</span></FwbTimelinePoint>
              <FwbTimelineContent>
                <FwbTimelineTitle>I have of lot of users, can Otago withstand the load?</FwbTimelineTitle>
                <FwbTimelineBody>
                  Otago services are designed to be very light and scalable, we take the challenge to support any volume
                  of users.
                </FwbTimelineBody>
              </FwbTimelineContent>
            </FwbTimelineItem>
            <FwbTimelineItem>
              <FwbTimelinePoint><span class="font-bold">?</span></FwbTimelinePoint>
              <FwbTimelineContent>
                <FwbTimelineTitle>Is Otago secured?</FwbTimelineTitle>
                <FwbTimelineBody>
                  We take security very seriously, we regurlarly update our servers and dependencies, monitor for any
                  suspicious behavior. We also highly recommend you to sign your updates with a private key that you
                  keep safe.
                </FwbTimelineBody>
              </FwbTimelineContent>
            </FwbTimelineItem>
            <FwbTimelineItem>
              <FwbTimelinePoint><span class="font-bold">?</span></FwbTimelinePoint>
              <FwbTimelineContent>
                <FwbTimelineTitle>
                  I started a deployment but I don't have enough credits, what will happen?
                </FwbTimelineTitle>
                <FwbTimelineBody>
                  The deployment will go on (we never stop ongoing deployments), and you can refill your account later.
                </FwbTimelineBody>
              </FwbTimelineContent>
            </FwbTimelineItem>
            <FwbTimelineItem>
              <FwbTimelinePoint><span class="font-bold">?</span></FwbTimelinePoint>
              <FwbTimelineContent>
                <FwbTimelineTitle>Does Otago comply with Play Store and App Store guidelines?</FwbTimelineTitle>
                <FwbTimelineBody>
                  Yes, both stores allow you to update code that runs in a virtual machine or an interpreter, as long as
                  your changes are not deceptive or malicious.
                </FwbTimelineBody>
              </FwbTimelineContent>
            </FwbTimelineItem>
            <FwbTimelineItem>
              <FwbTimelinePoint><span class="font-bold">?</span></FwbTimelinePoint>
              <FwbTimelineContent>
                <FwbTimelineTitle>How do volume discounts work?</FwbTimelineTitle>
                <FwbTimelineBody>
                  You get a bonus if you make a payment that exceeds specified amounts (you may refill for the year).
                  For example, if you pay $1000 you will get 1100 credits, which is equivalent to a bonus of 1M user
                  installs.
                </FwbTimelineBody>
              </FwbTimelineContent>
            </FwbTimelineItem>
          </FwbTimeline>
        </div>
      </PageBlock>
    </main>
    <PageFooter />
  </div>
</template>

<script setup lang="ts">
import {
  FwbAvatar,
  FwbTimeline,
  FwbTimelineBody,
  FwbTimelineContent,
  FwbTimelineItem,
  FwbTimelinePoint,
  FwbTimelineTitle,
} from "flowbite-vue";

const {
  app: { credit },
} = useRuntimeConfig();

const seo = {
  title: "Otago",
  description:
    "Custom server for expo-updates and react-native OTA updates. Send your code pushes to instantly fix bugs or add new features to your ios and android mobile app users.",
};
useHead({
  title: seo.title,
  meta: [
    { name: "description", content: seo.description },
    { property: "og:title", content: seo.title },
    { property: "og:description", content: seo.description },
  ],
});
</script>

<style module>
@import url("~/assets/css/modules.css");
</style>

<style scoped>
h2 {
  @apply mb-12 text-4xl lg:text-5xl font-extrabold text-center;
}

.card {
  @apply block bg-white rounded-xl border border-gray-200 shadow-lg dark:bg-zinc-900
    dark:border-zinc-700 dark:hover:border-blue-400;
}

.head {
  @apply pt-12 px-4 mx-auto max-w-screen-xl text-center;

  h1 {
    @apply mb-12 sm:mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl dark:text-blue-400
      lg:text-6xl;
  }

  > div {
    @apply mb-12 sm:mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-0 lg:px-16 dark:text-gray-300;

    span {
      @apply font-medium dark:text-blue-400;
    }
  }
}

#service {
  ul {
    @apply text-lg text-gray-500 dark:text-gray-300;

    li + li {
      @apply mt-1;
    }
  }

  span {
    @apply text-blue-400;
  }

  .terminal {
    @apply w-[500px] min-h-[200px] max-w-[90vw] px-4 py-3 rounded-md bg-zinc-800 border border-zinc-700;
  }
}

.story-card {
  @apply flex max-md:flex-col items-center p-8 max-md:text-center;

  .content {
    @apply md:px-6 italic text-lg max-md:pt-6;

    p + p {
      @apply mt-2;
    }

    p span {
      @apply not-italic;
    }
  }
}

.pricing {
  @apply grid grid-cols-1 md:grid-cols-3 justify-items-center max-w-screen-md mx-auto;

  .pricing-card {
    @apply w-[256px] min-h-44 md:min-h-72;

    &.pricing-main {
      @apply scale-110;

      h3 {
        @apply dark:text-blue-400;
      }
    }

    span {
      @apply text-blue-400;
    }

    > div {
      @apply p-6;

      h3 {
        @apply mb-5 text-center text-2xl font-bold tracking-tight text-gray-300;
      }

      p {
        @apply text-gray-700 dark:text-gray-300 my-3;
      }
    }
  }
}

.faq {
  @apply px-5;

  h3 {
    @apply py-1;
  }
}
</style>
